import { Injectable, EventEmitter } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AccessToken } from 'app/model/security/access-token';
import { User } from 'app/model/security/user';
import { GlobalVariables } from '../global-variables';
import { mapFunctionality, mapHomePageByGroup } from '../utils';
import { SearchCriteria } from 'app/model/data-page';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../store/login/login.state';
import {State} from '../../store/login/reducers/authentication.reducer';
import {LogoutFailure, LogoutSuccess} from '../../store/login/actions/authentication.action';
import {ConfirmUser} from '../../model/security/confirm-user';
import {RuleState, selectRuleState} from '../../store/rule/rule.state';
import {UserRuleState} from '../../store/rule/reducers/rule.reducer';
import {Role} from '../../model/security/rule';
import {MemberShip} from "../../model/register/MemberShip";
import {RegisterService} from "../register/register.service";
import {Organisation} from "../../model/organisation/Organisation";

@Injectable({
  providedIn: 'root'
})
export class UserAppService {

  userToken: string;

  /**
   * local storage of user navigation data
   */
  protected userNavigationData: Map<string, any> = new Map<string, any>();

  /**
   * Emitter to detect when user lang is changed
   */
  public userLangChanged: EventEmitter<any> = new EventEmitter<any>();
  /**
   * Event emit when the user log in or out
   */
  public userLoggedChanged: EventEmitter<{logged: boolean, user?: User}> = new EventEmitter<{logged: boolean, user?: User}> ();

  /**
   * Event emit when the user log in or out
   */
  public isHomeURL: EventEmitter<boolean> = new EventEmitter<boolean> ();

  /**
   * Event emit when user privileges change
   */
  public userPrivilegesChanged: EventEmitter<any> = new EventEmitter<any>();
  // Map of user privileges
  public userPrivileges: Map<string, string[]> = new Map<string, string[]>();
  public allow_all: boolean;
  protected ALL_ACTION = 'ALL';

  /**
   * Current user connected
   */
  protected userLoggedIn: User;
  protected userRules: string[];
  protected isAuthenticated: boolean;

  authenticationState$: Observable<any>;
  rule$: Observable<any>;

  authenticationSubscription: Subscription;
  ruleSubscription: Subscription;

  currentMemberShip = new MemberShip();
  currentOrganisation = new Organisation();


  constructor( protected http: HttpClient,
               protected store: Store<LoginState>,
               protected ruleStore: Store<RuleState>,
               protected router?: Router) {
    this.isHomeURL.emit(false);

    this.allow_all = false;

    this.authenticationState$ = this.store.select(selectAuthenticationState);
    this.rule$ = this.ruleStore.select(selectRuleState);

    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      this.userLoggedIn = state.user.user;
      this.isAuthenticated = state.isAuthenticated;
      this.userToken = state.user.token;

      if (!this.isOrganisation()) {
        this.findMemberShipByLogin(this.userLoggedIn.login).then(memberShip => {
          this.currentMemberShip = memberShip;
        });
      } else {
        this.findOrganisationByLogin(this.userLoggedIn.login).then(organisation => {
          this.currentOrganisation = organisation;
        });
      }
    });

    this.ruleSubscription = this.rule$.subscribe((state: UserRuleState) => {
      this.userRules = state.userRules;
      const action = this.userRules.find(c => c === Role.ADMIN);
      if (action) { this.allow_all = true; }
    });
  }


  protected getHttpOptions() {
      return {
        headers: new HttpHeaders(
          {
              'Content-Type': 'application/json',
          })
    };
  }

  public isUserLoggedIn(): boolean {
    return this.isAuthenticated;
  }

  public isOrganisation(): boolean {
    return this.userLoggedIn.isOrganisation;
  }

  public login(user: User): Observable<AccessToken> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/connect`,
      user,
      this.getHttpOptions());
  }

  public resetPassword(user: User): Observable<User> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/reset/password`,
      user,
      this.getHttpOptions());
  }

  public sendCodeForReset(email: string): Observable<string> {
    const cri: SearchCriteria = new SearchCriteria(email);
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/reset`,
      cri,
      this.getHttpOptions());
  }

  public confirm(confirmUser: ConfirmUser): Observable<AccessToken> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/confirm`,
      confirmUser,
      this.getHttpOptions());
  }

  public resetPasswordConfirm(confirmUser: ConfirmUser): Observable<any> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/reset/confirm`,
      confirmUser,
      this.getHttpOptions());
  }

  public refreshToken(refreshToken: string): Observable<AccessToken> {
    refreshToken = refreshToken.replace(/\"/g, "");
    const criteria = new SearchCriteria(refreshToken);
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/refreshToken`,
      criteria,
      this.getHttpOptions());
  }

  public rules(): Observable<{authority: string[]}> {
    return this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/rules`,
      this.getHttpOptions());
  }

  public logout(refreshToken: string){
    refreshToken = refreshToken.replace(/\"/g, "");
    const criteria = new SearchCriteria(refreshToken);
    this.http.post<any>(
      `${GlobalVariables.CONTEXT_PATH}/security/disconnect`,
      criteria,
      this.getHttpOptions()).subscribe(() => {
      this.store.dispatch(new LogoutSuccess());
    }, error => {
      this.store.dispatch(new LogoutFailure(error));
    });
  }

  public getUserAppLabel(): string {
    const user = this.getUser();
    const text = (user ? user.login : '' );
    return text;
  }

  public getUser(): User {
    return this.userLoggedIn;
  }

  public getMemberShip(): MemberShip {
    return this.currentMemberShip;
  }

  public getOrganisation(): Organisation {
    return this.currentOrganisation;
  }
  private getUSerAccess(menuCode: string) {
    const role = mapFunctionality.get(menuCode);
    // if role don't exist inside a map return no access
    if (!role) { return false; }
    return this.evaluateUserRole(role);
  }

  private evaluateUserRole(role: string) {
    if (this.beforeCheckAccessRule()) { return false; }
    return this.userRules.includes(role);
  }

  /**
   * Check if a user has access to some functionnality
   */
  public hasAccess(menuCode: string): boolean {
     if (this.allow_all === true) { return true; }
     if (!menuCode) { return false; }
     if (menuCode.includes('cancel') || (menuCode.includes('select'))) { return true; }

     return this.getUSerAccess(menuCode);
}

  public updateNavigationData(componentName: string , data: any ){
    if (componentName != null ){
      this.userNavigationData.set(componentName, data);
    }
  }

  public getHomeUrl(): string[] {
    const defaultPage: string[] = GlobalVariables.AFTER_LOGIN_ROUTE;
    if (this.beforeCheckAccessRule()) { return defaultPage; }
    return this.evaluateGroup(this.userRules, defaultPage);
  }

  private evaluateGroup(groups: string[], defaultPage: string[]) {
    if (this.allow_all) { return ['dashboard']; }
    if (!mapHomePageByGroup) { return defaultPage; }
    groups.forEach(group => {
      const homePage = mapHomePageByGroup.get(group);
      if (homePage) {
        defaultPage = homePage;
        return;
      }
    });
    return defaultPage;
  }

  public getNavigationData(componentName: string){
    if (componentName != null ){
      return this.userNavigationData.get(componentName);
    }
    return null;
  }

  private beforeCheckAccessRule(): boolean {
    return  !this.userToken ||
      !this.userRules ||
      this.userRules.length === 0;
  }

  findMemberShipByLogin(login: string): Promise<MemberShip> {
    const cri: SearchCriteria = new SearchCriteria(login);
    return new Promise<MemberShip>((resolve, reject) => {
      this.http.post<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/member-ship/find-by-login`,
        cri,
        this.getHttpOptions()
      ).subscribe((memberShip: MemberShip) => {
        resolve(memberShip);
      }, (error => {
        reject(error);
      }));
    });
  }

  findOrganisationByLogin(login: string): Promise<Organisation> {
    return new Promise<Organisation>((resolve, reject) => {
      this.http.get<any>(
        `${GlobalVariables.CONTEXT_PATH}/organisation/find-by-login/${login}`,
      ).subscribe((organisation: Organisation) => {
        resolve(organisation);
      }, (error => {
        reject(error);
      }));
    });
  }
}


