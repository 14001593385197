import { AbstractControl } from '@angular/forms';

export class NobleValidators {
    public static notEmpty(control: AbstractControl) {
        if (control && control.value != null && (typeof control.value === 'string')  && control.value.trim() === '') {
            return {notEmpty: false};
        }
        return null;
    }

    public static notObject(control: AbstractControl) {
      if (control && control.value !== undefined && control.value !== null && (control.value.id === undefined || control.value.id === null)) {
        return {notObject: true};
      }
      return null;
    }

    public static notNumber(control: AbstractControl) {
      if (control && control.value !== undefined && control.value !== null && (control.value.match('^[0-9]*$') !== null)) {
        return { notNumber: true};
      }
      return null;
    }

    public static notAfterCurrentDay(control: AbstractControl) {
        if (control && control.value && control.value > new Date()) {
            return {notAfterCurrentDay: false};
        }
        return null;
    }

    public static dataWithIdSelected(control: AbstractControl) {
        if (control && control.value != null && (typeof control.value === 'string')  && control.value.trim() !== '') {
            return {dataWithIdSelected: false};
        }
        return null;
    }

}
