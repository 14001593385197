import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import {UserAppService} from '../user-service/user-app.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardGuard implements CanActivate {

  public routeChanged: EventEmitter<string> = new EventEmitter();
  protected previousUrl: string;

  constructor(private router: Router, private userService: UserAppService){
    // retrieve previous url
    router.events.subscribe( (event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = event.url;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const currentUrl: string = state.url !== null ? state.url : route.url != null && route.url.length > 0 ? route.url[0].path : '';

    // When user tries to access the login page but already logs in.
    if ((currentUrl.includes('login')  || currentUrl.includes('register')) && this.userService.isUserLoggedIn() === true){
      this.router.navigate(['documents/list']);
      return false;
    }

    // when user tries to access to dashboard but don't logs in.
    if ((currentUrl.includes('documents/list') || currentUrl.includes('deposit/make')) && this.userService.isUserLoggedIn() === false) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
