import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import {Funtions} from '../model/security/funtionalities';
import {Role} from '../model/security/rule';

export class Base64 {
    public static encode(str: string) {
        try{
            return btoa(str);
        }catch (e){
            console.error('Base64.encode ', e);
        }
    }
    public static decode(str: string) {
        try{
            return atob(str);
        }catch (e){
            console.error('Base64.decode ', e);
        }
    }

    public static convertBase64ToBlob( base64: string, type?: string) {
        // convert base64 to raw binary data held in a string
       // if(base64 == null || base64 == "") return "";
        try {
            const byteString = atob(base64);
            // write the bytes of the string to an ArrayBuffer
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            // write the ArrayBuffer to a blob, and you're done
            const bb = new Blob([ab], {type});
            return bb;
        }catch (e){
            console.error(' Utils.convertBase64ToBlob error ', e);
        }
        return null;
    }
}

export class ServiceUtils {
    public static handleError(error: HttpErrorResponse, doSomething?: any) {
        let errorData = error.error;
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.

        } else {
          if (error.status === 401){
            errorData = error.error;
          } else if (error.status >= 500 && error.status < 600){
            errorData = error.error;
            const indexOfServerInaccessibleError = error.error.indexOf('Error occured while trying to proxy to');
            if (indexOfServerInaccessibleError !== -1){
                errorData = 'http.error.server.unreachable.error';
            }
          }
        }
        return throwError(errorData);
    }
}

// Table de correspondance entre les rôles correspondant aux différentes fonctionnalités
// il sera intéressant d'utiliser les noms des étiquettes sur les boutons pour désigner la fonctionnalité
// cela évitera de recréer un rôle qui existe déjà sous un nom différent
export const mapFunctionality: Map<string, string> = new Map<string, string>(
  [
    [ Funtions.dashboard, Role.DASHBOARD],

    [ Funtions.make_deposit, Role.MAKE_DEPOSIT],

    [ Funtions.association, Role.ASSOCIATION],

    [ Funtions.member_btn_new_fct, Role.CREATE_MEMBER],
    [ Funtions.member_btn_list_fct, Role.SHOW_MEMBER],

    [ Funtions.transaction, Role.TRANSACTION],

    [ Funtions.notification, Role.NOTIFICATION],

    [ Funtions.partner_btn_list_fct, Role.SHOW_PARTNER],
  ]
);

export const mapHomePageByGroup: Map<string, string[]> = new Map<string, string[]>(
  [
    [ Role.DASHBOARD, ['dashboard']],

    [ Role.MAKE_DEPOSIT, ['deposit/make']],

    [ Role.ASSOCIATION, ['association']],

    [ Role.CREATE_MEMBER, ['member/edit']],
    [ Role.SHOW_MEMBER, ['member/list']],

    [ Role.TRANSACTION, ['transaction/list']],

    [ Role.NOTIFICATION, ['notification/list']],

    [ Role.SHOW_PARTNER, ['partner/list']],
  ]
);
