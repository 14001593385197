import {ClassNameMap, Persistent} from '../persistent/persistent';

export class ConfirmUser extends Persistent{

  public token: string;

  constructor(id?: number, token?: string){
    super();
    this.id = id;
    this.token = token;
    this.classe = ClassNameMap.ConfirmUser;
  }
}
