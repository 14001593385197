import {ClassNameMap, Persistent} from '../persistent/persistent';

export class Basket extends Persistent{
  currency?: string;
  amount?: string;
  name?: string;
  email?: string;

  constructor(props: {amount: string,  name?: string, email?: string}) {
    super();
    this.amount = props.amount;
    this.email = props.email;
    this.name = props.name;
    this.currency = 'EUR';
    this.classe = ClassNameMap.Basket;
  }
}
