import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAppService } from '../../user-service/user-app.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userService: UserAppService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      request = request.clone({headers: request.headers.delete('Authorization')});

      // add authorization header with jwt token if available
      const currentUserToken = this.userService.userToken;
      if (currentUserToken && currentUserToken !== null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUserToken.replace(/\"/g, "")}`
                }
            });
        }
      return next.handle(request);
    }
}
