import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {GlobalVariables} from '../../services/global-variables';
import {DateAdapter} from '@angular/material/core';
import {MatSidenav} from '@angular/material/sidenav';
import {UserAppService} from 'app/services/user-service/user-app.service';
import {Router} from '@angular/router';
import {LoginService} from 'app/services/login/login.service';
import {LanguageService} from 'noble-retour/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  @Input() deviceXs: boolean;
  @Input() isHandset$: Observable<boolean>;
  @Input() drawer: MatSidenav;
  @Input() userLogged: boolean;

  currentLang: Language;
  availableLanguages: Language[];

  url: string | ArrayBuffer = '';

  constructor(
    private translate: TranslateService,
    public languageService: LanguageService,
    protected userService: UserAppService,
    protected loginService: LoginService,
    protected router: Router,
    private dateAdapter: DateAdapter<any>) {

    this.initLanguages();

  }

  ngOnInit(): void {
    this.userService.userLangChanged.subscribe(lang => {
      this.langChooser(lang);
    });

    this.userService.isHomeURL.subscribe(value => {
      if (value === true) {
        this.drawer.close();
      }
    });
  }

  toggleDrawer() {
    this.drawer.toggle();
  }

  ngAfterViewInit() {
    this.toggleDrawer();
  }

  protected initLanguages = () => {

    this.translate.addLangs(GlobalVariables.AVAILABLE_LANG);
    const availableLanguages = [];
    GlobalVariables.AVAILABLE_LANG.forEach( (lang) => {
      availableLanguages.push({value: lang, labelCode: 'app.lang.' + lang + '.label'});
    });
    this.availableLanguages = availableLanguages;
    const browserLang = this.translate.getBrowserLang();
    const langIndex = this.availableLanguages.findIndex( (lang) => lang.value === browserLang);
    this.currentLang = this.availableLanguages[langIndex !== -1 ? langIndex : 0];
    this.translate.setDefaultLang(this.currentLang.value);
    this.translate.use(this.currentLang.value);
    this.dateAdapter.setLocale(this.currentLang.value);
  }

  langChooser = (lang: Language) => {
    this.currentLang = lang;
    this.translate.use(this.currentLang.value);
    this.dateAdapter.setLocale(this.currentLang.value);
    if (this.userService){
      this.userService.userLangChanged.emit(lang);
    }
  }

  launchHome(){
    this.router.navigate(GlobalVariables.AFTER_LOGIN_ROUTE);
  }

  manageAccount(){
  }

  public logOutUser() {
    this.loginService.logOut();
  }
}

export interface Language {
  value: string;
  labelCode: string;
}
