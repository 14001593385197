<div class="register-zone align-items">
    <mat-card class="row register-container">
        <mat-card-header class="align-items">
            <mat-card-title>
                <h2 class="register-title">
                    {{"register.form.title"| translate}}
                </h2>
            </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <br>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 register-form-header">
                <img src="assets/images/card.png" class="card-img">

                <div class="col-11 col-sm-11 col-md-11">
                    <div class="register-form-title">
                        <span>{{"register.form.label"| translate}}</span>
                    </div><br>
                    <span class="register-form-description">
                        {{"register.form.description.label"| translate}}
                    </span>
                    <a class="membership-condition" [routerLink]="'/home/membership'">
                        {{"register.form.membership-condition.label" | translate}}
                    </a>

                    <br><br>

                    <div class="already-account" fxLayout="column" fxLayoutAlign="center center">
                        <span> {{"register.form.already-account.label" | translate}}</span>
                        <a class="link" [routerLink]="'/login'">{{"login.btn.label" | translate}}</a>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-8">
              <mat-tab-group mat-stretch-tabs class="exemple-stretch-tabs mat-elevation-z4">
                <mat-tab label="{{'register.sheet.title.label' | translate}}">
                  <mat-horizontal-stepper labelPosition="bottom" [linear]="isLinear" #stepper >
                    <mat-step [stepControl]="formGroup1" [completed]="isStep1Valid()" [optional]="isOptional" [editable]="isEditable" >
                      <form [formGroup]="formGroup1">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.step1.label' | translate }}</ng-template>-->

                        <mat-card class="">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step1.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <ng-template>
                                <section class="example-section">
                                  <mat-slide-toggle
                                    class="example-margin"
                                    [color]="color"
                                    [checked]="checked"
                                    [disabled]="disabled2">
                                    {{ 'register.sheet.organisation.label' | translate }}
                                  </mat-slide-toggle>
                                </section>
                              </ng-template>
                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.name.label' | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.name" [formControl]="nameControl" required >
                                <mat-error *ngIf="nameControl.hasError('required')">{{ "register.form.name.required" | translate}}</mat-error>
                                <mat-error *ngIf="nameControl.errors && !nameControl.errors.notEmpty">{{ "validator.not.empty" | translate}}</mat-error>
                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.userName.label' | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.surname" [formControl]="userNameControl" required >
                                <mat-error *ngIf="userNameControl.hasError('required')">{{ "register.form.userName.required" | translate}}</mat-error>
                                <mat-error *ngIf="userNameControl.errors && !userNameControl.errors.notEmpty">{{ "validator.not.empty" | translate}}</mat-error>
                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.email.label' | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.emailAddress" [formControl]="emailControl" required >
                                <mat-icon matSuffix>email</mat-icon>
                                <mat-error *ngIf="emailControl.getError('required')">{{"register.form.email.required"  | translate}}</mat-error>
                                <mat-error *ngIf="emailControl.getError('pattern')">{{ "pattern.email.error" | translate}}</mat-error>
                              </mat-form-field>
                              <mat-form-field  class="col-xs-12 col-sm-8">
                                <mat-label>{{ 'register.form.birth.date.label' | translate }}</mat-label>
                                <input [formControl]="birthDateControl" matInput #birthDateMatInput [matDatepicker]="picker" [max]="maxDate"
                                       placeholder="{{ 'register.form.birth.date.placeholder' | translate }}"
                                       [(ngModel)]="currentObject.birthdate" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                              <mat-form-field  class="col-xs-12 col-sm-4">
                                <mat-label>{{ 'register.form.age.label' | translate }}</mat-label>
                                <input matInput placeholder="{{ 'register.form.age.placeholder' | translate}}"
                                       disabled='true' [value]="age">
                              </mat-form-field>

                            </div><br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" (click)="step1Next()" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}
                                <mat-icon>skip_next</mat-icon>
                              </button>
                            </div>

                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="formGroup2" [completed]="isStep2Valid()" [optional]="isOptional" [editable]="isEditable" >
                      <form [formGroup]="formGroup2">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.step2.label' | translate }}</ng-template>-->

                        <mat-card class="">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step2.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{"register.form.nativeCountry.label" | translate}} *</mat-label>
                                <mat-chip-list #chipList>
                                  <mat-chip
                                    *ngFor="let c of countriesN"
                                    selectable="true"
                                    [removable]="removable"
                                    (removed)="removeNativeCountry()">
                                    {{displayCountryFn(currentObject.nativeCountry)}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                  </mat-chip>
                                  <input
                                    #nativeInput
                                    [formControl]="nativeCountryControl"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add($event)" required>
                                </mat-chip-list>
                                <mat-error *ngIf="nativeCountryControl.hasError('required')">{{ "register.form.nativeCountry.required" | translate}}</mat-error>
                                <mat-error *ngIf="nativeCountryControl.errors && nativeCountryControl.errors.notObject">{{ "validator.not.object" | translate}}</mat-error>
                                <mat-error *ngIf="nativeCountryControl.hasError('notEmpty')">{{ "validator.not.empty" | translate}}</mat-error>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayCountryFn">
                                  <mat-option  *ngFor="let row of nativeList" [value]="row">{{displayCountryFn(row)}}</mat-option>
                                </mat-autocomplete>
                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{"register.form.residenceCountry.label" | translate}} *</mat-label>
                                <mat-chip-list #chipList2>
                                  <mat-chip
                                    *ngFor="let r of countriesR"
                                    selectable="true"
                                    [removable]="removable"
                                    (removed)="removeResidenceCountry()">
                                    {{displayCountryFn(currentObject.residenceCountry)}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                  </mat-chip>
                                  <input
                                    #residenceInput
                                    [formControl]="residenceCountryControl"
                                    [matAutocomplete]="autoResidence"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="add2($event)" required>
                                </mat-chip-list>
                                <mat-error *ngIf="residenceCountryControl.getError('required') != null">{{ "register.form.residenceCountry.required" | translate}}</mat-error>
                                <mat-error *ngIf="residenceCountryControl.errors && residenceCountryControl.errors.notObject">{{ "validator.not.object" | translate}}</mat-error>
                                <mat-autocomplete #autoResidence="matAutocomplete" (optionSelected)="selected2($event)" [displayWith]="displayCountryFn">
                                  <mat-option
                                    *ngFor="let residence of residenceList"
                                    [value]="residence">
                                    {{displayCountryFn(residence)}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>
                              <!--<mat-form-field  class="col-xs-12 col-sm-4 col-md-4">
                                <mat-label>{{ "register.form.code.placeholder" | translate }}</mat-label>
                                <input matInput disabled='true' [value]="phoneCountryCode" />
                              </mat-form-field>-->
                              <mat-form-field class="col-xs-12 col-sm-8 col-md-8">
                                <mat-label>{{"register.form.phone.label" | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.mobilePhone" [formControl]="phoneControl" (input)="formatPhoneNumber($event)" required >
                                <mat-icon matSuffix>phone_android</mat-icon>
                                <mat-error *ngIf="phoneControl.getError('required')">{{ "register.form.phone.required" | translate}}</mat-error>
                                <mat-error *ngIf="phoneControl.getError('pattern')">{{ "pattern.phone.error" | translate}}</mat-error>
                              </mat-form-field>
                            </div><br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>
                              <button  (click)="step2Next()" mat-raised-button color="primary" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}<mat-icon>skip_next</mat-icon>
                              </button>

                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="formGroup3" [completed]="isStep3Valid()" [optional]="isOptional" [editable]="isEditable" >
                      <form [formGroup]="formGroup3">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.step3.label' | translate }}</ng-template>-->

                        <mat-card class="">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step3.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{"register.form.city.label" | translate}} *</mat-label>
                                <mat-chip-list #chipList3>
                                  <mat-chip
                                    *ngFor="let c of citiesC"
                                    selectable="true"
                                    [removable]="removable"
                                    (removed)="removeCity()">
                                    {{displayCityFn(currentObject.city)}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                  </mat-chip>
                                  <input
                                    #cityInput
                                    [formControl]="cityControl"
                                    [matAutocomplete]="autoCity"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    (matChipInputTokenEnd)="addCity($event)" required>
                                </mat-chip-list>
                                <mat-error *ngIf="cityControl.invalid">{{ "register.form.city.required" | translate}}</mat-error>
                                <mat-error *ngIf="cityControl.errors && cityControl.errors.notObject">{{ "validator.not.object" | translate}}</mat-error>
                                <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="selectedCity($event)" [displayWith]="displayCityFn">
                                  <mat-option
                                    *ngFor="let city of cities"
                                    [value]="city">
                                    {{displayCityFn(city)}}
                                  </mat-option>
                                </mat-autocomplete>
                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.address.label' | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.address.number" [formControl]="addressControl" required >
                                <mat-error *ngIf="addressControl.invalid">{{ "register.form.address.required" | translate}}</mat-error>
                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.postalCode.label' | translate}}</mat-label>
                                <input matInput [(ngModel)]="currentObject.city.postCode" maxlength="10"
                                       [formControl]="postalCodeControl" required >
                                <mat-error *ngIf="postalCodeControl.invalid">{{ "register.form.postalCode.required" | translate}}</mat-error>
                              </mat-form-field>

                            </div><br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>

                              <button (click)="step3Next()"mat-raised-button color="primary" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}<mat-icon>skip_next</mat-icon>
                              </button>

                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>


                    <mat-step [stepControl]="formGroup4" [optional]="true" [editable]="isEditable" >
                      <form [formGroup]="formGroup4">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.step4.label' | translate }}</ng-template>-->

                        <mat-card class="">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step4.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <mat-tab-group mat-stretch-tabs dynamicHeight="true">

                              <mat-tab label="Person">
                                <div class="row select-god-father">
                                  <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                    <mat-chip-list #chipList4>
                                      <mat-chip
                                        *ngFor="let g of godFatherR"
                                        selectable="true"
                                        [removable]="removable"
                                        [value]="g"
                                        (removed)="removeGodFather()">
                                        {{displayGodFatherFn(currentObject.godFather)}}
                                        <mat-icon matChipRemove *ngIf="!disabled">cancel</mat-icon>
                                      </mat-chip>
                                      <input
                                        [disabled]="disabled"
                                        placeholder="{{'register.form.godFather.label' | translate}}"
                                        #godFatherInput
                                        [formControl]="godFatherControl"
                                        [matAutocomplete]="autoGodFather"
                                        [matChipInputFor]="chipList4"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="addOnBlur"
                                        (matChipInputTokenEnd)="addGodfather($event)">
                                      <button matSuffix mat-button matTooltip="{{'register.form.godFather.toolTip' | translate}}">
                                        <mat-icon>live_help</mat-icon>
                                      </button>
                                    </mat-chip-list>
                                    <mat-autocomplete #autoGodFather="matAutocomplete" (optionSelected)="selectedGodFather($event)" [displayWith]="displayGodFatherFn">
                                      <mat-option
                                        *ngFor="let godFather of godFathers"
                                        [value]="godFather">
                                        {{displayGodFatherFn(godFather)}}
                                      </mat-option>
                                    </mat-autocomplete>
                                    <mat-error *ngIf="godFatherControl.invalid">{{ "register.form.godFather.required" | translate}}</mat-error>
                                  </mat-form-field>

                                </div>
                              </mat-tab>
                              <mat-tab label="Other">

                                <section class="example-section">
                                  <mat-radio-group [(ngModel)]="chose" [ngModelOptions]="{standalone: true}">
                                    <mat-radio-button (click)="clickChose()" [(ngModel)]="chose1" [labelPosition]="'after'" class="example-margin" value="yes">
                                      {{"registration.form.other.chose" | translate}}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </section>

                                <ng-template [ngIf]="chose === 'yes'">
                                  <section class="example-section">
                                  <span class="example-list-section">
                                    <ul>
                                      <li><mat-checkbox [(ngModel)]="internet" [ngModelOptions]="{standalone: true}">{{"register.form.other.internet" | translate}}</mat-checkbox></li>
                                      <li><mat-checkbox [(ngModel)]="network" [ngModelOptions]="{standalone: true}">{{"register.form.other.network" | translate}}</mat-checkbox></li>
                                    </ul>
                                  </span>
                                  </section>
                                </ng-template>

                                <section class="example-section">
                                  <mat-radio-group  [(ngModel)]="specified" [ngModelOptions]="{standalone: true}">
                                    <mat-radio-button (click)="clickSpecified()" [(ngModel)]="specifiedChose" [labelPosition]="'after'" class="example-margin" value="yes">
                                      {{"registration.form.other.specified" | translate}}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </section>

                                <ng-template [ngIf]="specified === 'yes'">
                                  <section class="example-section">
                                <span class="example-list-section">
                                  <ul>
                                    <li>
                                      <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                        <input
                                          matInput
                                          placeholder="{{'register.form.other.specified.label' | translate}}"
                                          [formControl]="specifiedControl">
                                      </mat-form-field>
                                    </li>
                                  </ul>
                                </span>
                                  </section>
                                </ng-template>
                              </mat-tab>
                            </mat-tab-group>

                            <br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>

                              <button (click)="step4Next()" mat-raised-button color="primary" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}<mat-icon>skip_next</mat-icon>
                              </button>

                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="optionGroup" [completed]="optionStepValid()" [optional]="isOptional" [editable]="isEditable" >
                      <form [formGroup]="optionGroup">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.option.label' | translate }}</ng-template>-->

                        <mat-card class="optios-class">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.option.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <label id="example-radio-group-label">{{'register.option.title' | translate}}</label>
                            <mat-radio-group formControlName="option"
                                             aria-labelledby="example-radio-group-label"
                                             class="example-radio-group">
                              <ng-container *ngFor="let option of options" >
                                <mat-radio-button class="example-radio-button"
                                                  (change)="radioChange($event)"
                                                  [value]="option">
                                  {{option.text | translate}}
                                </mat-radio-button>
                              </ng-container>
                            </mat-radio-group>
                            <div>{{favoriteOption?.description | translate}}</div>
                            <br>

                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>

                              <button (click)="optionStepNext()"mat-raised-button color="primary" mat-button matStepperNext>
                                {{ 'stepper.next.label' | translate }}<mat-icon>skip_next</mat-icon>
                              </button>

                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>

                    <mat-step [stepControl]="formGroup5" [completed]="isStep5Valid()" [optional]="isOptional" [editable]="isEditable" >
                      <form [formGroup]="formGroup5">
                        <!--<ng-template matStepLabel>{{ 'register.sheet.step5.label' | translate }}</ng-template>-->

                        <mat-card class="">
                          <mat-card-header>
                            <mat-card-title><mat-label class="label-step-register">{{'register.sheet.step5.label' | translate}}</mat-label></mat-card-title>
                          </mat-card-header>
                          <mat-card-content>
                            <div class="row">
                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.password.label' | translate}}</mat-label>
                                <input type="password" matInput [(ngModel)]="currentObject.user.password" [formControl]="passwordControl" required >
                                <mat-error *ngIf="passwordControl.getError('required')">{{ "register.form.password.required" | translate}}</mat-error>
                                <mat-error *ngIf="passwordControl.invalid">{{ "pattern.password.error" | translate}}</mat-error>

                              </mat-form-field>

                              <mat-form-field class="col-xs-12 col-sm-12 col-md-12">
                                <mat-label>{{'register.form.confirmPassword.label' | translate}}</mat-label>
                                <input type="password" matInput [(ngModel)]="currentObject.user.confirmPassword" [formControl]="confirmPasswordControl" required >
                                <mat-error *ngIf="confirmPasswordControl.getError('required')">{{ "register.form.confirmPassword.required" | translate}}</mat-error>
                                <mat-error *ngIf="confirmPasswordControl.getError('mismatch')">{{ "register.form.confirmPassword.mismatch.error" | translate}}</mat-error>
                              </mat-form-field>
                              <section class="example-section optios-class">
                                <div class="align-ckeck">
                                  <mat-checkbox [labelPosition]="'after'"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="checkTermsUse">
                                  </mat-checkbox>
                                  <span class="termsUse">
                                    <b>{{ "register.form.termsUse.yes.label" | translate}}</b>,
                                    {{ "register.form.termsUse.take.label" | translate}}
                                    <button mat-button  style="color: blue;" class="link" (click)="startDownload('R.O.I.NR')">R.O.I</button>
                                    {{ "register.form.termsUse.and.label" | translate}}
                                    <button mat-button  style="color: blue;" class="link" (click)="startDownload('Conditions_generales2020')">{{ "register.form.termsUse.condition.label" | translate}}</button>

                                    {{ "register.form.termsUse.respect.label" | translate}}
                                </span>
                                </div>
                              </section>
                              <section class="example-section optios-class">
                                <div class="align-ckeck">
                                  <mat-checkbox [labelPosition]="'after'"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="checkTermsUse2">
                                  </mat-checkbox>
                                  <span class="termsUse">
                                    <b>{{ "register.form.termsUse.yes.label" | translate}}</b>,
                                    {{ "register.form.termsUse.accept.label" | translate}}
                                    <button mat-button  style="color: blue;" class="link" (click)="startDownload('condition')">{{ "register.form.termsUse.condition2.label" | translate}}</button>

                                    {{ "register.form.termsUse.confirm.label" | translate}}
                                    <button mat-button  style="color: blue;" class="link" (click)="startDownload('declaration_relative_a_la_vie_privee_NR-ASBL')">{{ "register.form.termsUse.policy.label" | translate}}</button>
                                  </span>
                                </div>
                              </section>
                            </div>
                            <br>
                            <mat-divider></mat-divider>

                            <div class="steper-btns">
                              <button mat-raised-button color="primary" mat-button matStepperPrevious>
                                <mat-icon>skip_previous</mat-icon>{{ 'stepper.back.label' | translate }}
                              </button>

                              <button mat-raised-button mat-button
                                      (click)="save()" class="register-btn">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                <mat-icon>check_circle</mat-icon> {{ 'stepper.register.label' | translate }}
                              </button>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-step>
                  </mat-horizontal-stepper>
                </mat-tab>
                <mat-tab label="Association">
                  <app-partner></app-partner>
                </mat-tab>
              </mat-tab-group>
            </div>
        </div>
    </mat-card>

</div>

<file-downloader hidden #downloader class="hide"
                 [open-in-tab]="openFileInTab"
                 file-type="application/pdf"
                 succedCodeMessage="document.download.succeed"
                 failCodeMessage="document.download.fail"
></file-downloader>
