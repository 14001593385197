import {Component, HostBinding, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {DateAdapter} from '@angular/material/core';
import {UserAppService} from 'app/services/user-service/user-app.service';
import {Router} from '@angular/router';
import {NotificationService} from 'app/services/notification-service/notification.service';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';
import { LanguageService} from 'noble-retour/services';


@Component({
  selector: 'app-navbar-home',
  templateUrl: './navbar-home.component.html',
  styleUrls: ['./navbar-home.component.scss']
})
export class NavbarHomeComponent implements OnInit {

  @HostBinding('class')
  className = 'app-navbar';

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));

  isCollapsed = true;
  constructor(protected userService: UserAppService,
              public languageService: LanguageService,
              protected router: Router, protected msgService: NotificationService,
              private dateAdapter: DateAdapter<any>, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.isHandset$.subscribe(isHandset => console.log(isHandset));
  }
}

