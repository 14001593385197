import { Persistent, ClassNameMap } from '../persistent/persistent';
import { Address } from '../country/Address';

export class Party extends Persistent {
    public name?: string;
    public fullname?: string;
    public fullName?: string;
    public birthdate?: string;
    public emailAddress?: string;
    public mobilePhone?: string;
    public fixedPhone?: string;
    public webSiteAddress?: string;
    public fax?: string;
    public shortName?: string;
    public address?: Address;

    constructor(){
        super();
        this.address = new Address();
        this.classe = ClassNameMap.Party;
    }

    public static getAge(birthdate) {
        let yearOld = null;
        if (birthdate != null && birthdate != "" ){
            yearOld =  new Date(Date.now()).getFullYear() - new Date(birthdate).getFullYear();
        }
        return yearOld;
    }
}

export class Company extends Party {
    public vatNumber?: string;
  registrarOffice?: string;

  constructor() {
    super();
    this.classe = ClassNameMap.Company;
  }
}

export class PartyType {
    public static COMPANY = "Company";
    public static PERSON = "Person";
}
