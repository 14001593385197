
<mat-toolbar color="primary"
  fxLayout="row wrap"
  fxLayoutAlign="space-between center"
  class="mat-elevation-z6">
  <mat-toolbar-row>
    <button
      *ngIf="!(isHandset$ | async)"
      mat-icon-button
      (click)="toggleDrawer()"
      aria-label="menu icon">
      <mat-icon>menu</mat-icon>
    </button>
    <div mat-ripple routerLink="/home" class="navbar-logo">
      <img alt="logo" class="navbar-logo-image" src="assets/images/new-logo.png" height="38" width="38">
      <div style="color: white!important;" class="navbar-logo-text d-md-block">NOBLE RETOUR</div>
    </div>

    <span class="example-spacer"></span>

    <div class="ml-2 d-flex align-items-center"
         [matMenuTriggerFor]="langMenu"
         *ngIf="languageService.currentLanguage"
         attr.aria-label="{{'change-lang-btn.label' | translate}}"
         matTooltip="{{'change-lang-btn.label' | translate}}">
      {{languageService.currentLanguage.value | uppercase}}
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <mat-menu #langMenu="matMenu">
      <ng-container *ngFor="let lang of languageService.availableLanguages">
        <button mat-menu-item (click)="languageService.changeLanguage(lang)">{{lang.labelCode | translate}}
        </button>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-menu>
      <button *ngIf="!(isHandset$ | async)" mat-button class="example-icon" [matMenuTriggerFor]="userMenu"
      attr.aria-label="{{'toolbar-account-btn.label' | translate}}"
      matTooltip="{{'toolbar-account-btn.label' | translate}}">
        <mat-icon class="icons-account_circle">person</mat-icon>
        <mat-icon role="img"
            class="mat-icon notranslate material-icons mat-icon-no-color"
            aria-hidden="true">arrow_drop_down
          </mat-icon>
     </button>

     <mat-menu #userMenu="matMenu" >
      <!--<a mat-menu-item (click)="manageAccount()">
        <mat-icon>person</mat-icon>
        <span>{{"toolbar-account-btn.label" | translate}}</span>
      </a>-->
      <a mat-menu-item (click)="logOutUser()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{"log-out-btn.label" | translate}}</span>
      </a>
    </mat-menu>

  </mat-toolbar-row>

  <mat-toolbar-row
    fxLayoutAlign="space-around center"
    *ngIf="(isHandset$ | async)">

    <button
      mat-icon-button
      (click)="toggleDrawer()"
      aria-label="menu icon">
      <mat-icon>menu</mat-icon>
    </button>

    <span class="example-spacer"></span>

    <button mat-button class="example-icon" [matMenuTriggerFor]="userMenu2"
            attr.aria-label="{{'toolbar-account-btn.label' | translate}}"
            matTooltip="{{'toolbar-account-btn.label' | translate}}">
      <mat-icon class="icons-account_circle">person</mat-icon>
      <mat-icon role="img"
                class="mat-icon notranslate material-icons mat-icon-no-color"
                aria-hidden="true">arrow_drop_down
      </mat-icon>
    </button>

    <mat-menu #userMenu2="matMenu" >
      <!--<a mat-menu-item (click)="manageAccount()">
        <mat-icon>person</mat-icon>
        <span>{{"toolbar-account-btn.label" | translate}}</span>
      </a>-->
      <a mat-menu-item (click)="logOutUser()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{"log-out-btn.label" | translate}}</span>
      </a>
    </mat-menu>

  </mat-toolbar-row>
</mat-toolbar>

