import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDividerModule} from '@angular/material/divider';
import {MatRadioModule} from '@angular/material/radio';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatChipsModule} from '@angular/material/chips';
import {PortalModule} from '@angular/cdk/portal';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatTreeModule} from '@angular/material/tree';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSortModule} from '@angular/material/sort';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {A11yModule} from '@angular/cdk/a11y';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MainMenuComponent} from './components/main-menu/main-menu.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MenuItemComponent} from './components/main-menu/menu-item/menu-item.component';
import {HeaderComponent} from './components/header/header.component';
import {ModalComponent} from './components/base/modal/modal.component';
import {ButtonsBarComponent} from './components/base/buttons-bar/buttons-bar.component';
import {SecureTemplateComponent} from './components/base/buttons-bar/secure-template.component';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register-edit/register.component';
import {Router, RouterModule} from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MakeDepositComponent} from './components/deposit/make-deposit/make-deposit.component';
import {
  SnackBarMessageComponent,
  ConfirmDialogComponent,
  AppProgressBarComponent
} from './services/notification-service/notification.service';
import {UserAppService} from './services/user-service/user-app.service';
import {HttpService} from './services/http-service/http.service';
import {ConfirmDepositComponent} from './components/deposit/confirm-deposit/confirm-deposit.component';
import {AmountPipe} from './pipes/amount.pipe';
import {MemberListComponent} from './components/member/member-list/member-list.component';
import {MemberEditComponent} from './components/member/member-edit/member-edit.component';
import {MemberShowComponent} from './components/member/member-show/member-show.component';
import {ListAbstractComponent} from './components/base/abstract/list-abstract-component';
import {HomeComponent} from './components/home/home.component';
import {OurProjectComponent} from './components/home/our-project/our-project.component';
import {MembershipComponent} from './components/home/membership/membership.component';
import {RecruitmentComponent} from './components/home/recruitment/recruitment.component';
import {MakeDonationComponent} from './components/home/make-donation/make-donation.component';
import {JobsComponent} from './components/home/jobs/jobs.component';
import {ContactUsComponent} from './components/home/contact-us/contact-us.component';
import {BlogComponent} from './components/home/blog/blog.component';
import {IsLoginDirective} from './directives/login/is-login.directive';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/login/reducers/authentication.reducer';
import {MatCarouselModule} from '@ngmodule/material-carousel';
import {AboutUsComponent} from './components/home/about-us/about-us.component';
import {FaqComponent} from './components/home/faq/faq.component';
import {EffectsModule} from '@ngrx/effects';
import {AuthenticationEffect} from './store/login/effects/authentication.effect';
import {LoginService} from './services/login/login.service';
import {JwtInterceptor} from './services/http-interceptor/helpers/jwt.interceptor';
import {FooterBarComponent} from './components/home/footer-bar/footer-bar.component';
import {ProgressBarComponent} from './components/base/progress-bar/progress-bar.component';
import {ConfirmAccountComponent} from './components/account/confirm-account/confirm-account.component';
import {AutocompleteComponent} from './components/base/autocomplete/autocomplete.component';
import {MailConfirmComponent} from './components/account/mail-confirm/mail-confirm.component';
import {RegisterEffect} from './store/member-ship/effects/register.effect';
import {memberShipReducer} from './store/member-ship/reducers/register.reducer';
import { SpinnerOverlayComponent } from './components/base/spinner-overlay/spinner-overlay.component';
import {userRuleReducer} from './store/rule/reducers/rule.reducer';
import {RuleEffect} from './store/rule/effects/rule.effect';
import {NewsManagementEditorComponent} from './components/administration/news-management/news-management-editor/news-management-editor.component';
import {NewsManagementNavigateComponent} from './components/administration/news-management/news-management-navigate/news-management-navigate.component';
import {NewsManagementViewComponent} from './components/administration/news-management/news-management-view/news-management-view.component';
import {NewsComponent} from './components/home/news/news.component';
import {FileManagerComponent} from './components/file-manager/file-manager.component';
import {FileListComponent} from './components/file-manager/file-list/file-list.component';
import {DetailsComponent} from './components/file-manager/sidebars/details/details.component';
import {MainComponent} from './components/file-manager/sidebars/main/main.component';
import {FileDownloaderComponent} from './components/file-downloader/file-downloader.component';
import {StripePaymentComponent} from './components/deposit/stripe-payment/stripe-payment.component';
import {PaypalPaymentComponent} from './components/deposit/paypal-payment/paypal-payment.component';
import {OtherPaymentComponent} from './components/deposit/other-payment/other-payment.component';
import {NgxStripeModule} from 'ngx-stripe';
import {NobleModule} from '../@noble/noble.module';
import {nobleConfig} from './noble-config';
import {ErrorHttpInterceptor} from './services/http-interceptor/error-http-interceptor';
import {PartnerComponent} from './components/register/partner/partner.component';
import {SingleContactPersonComponent} from './components/single-contact-person/single-contact-person.component';
import {ViewportScroller} from '@angular/common';
import { ForgotPasswordComponent } from './components/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/login/reset-password/reset-password.component';
import {AppLayoutModule} from './components/navbar';
import {LanguageService} from 'noble-retour/services';

import * as hammer from 'hammerjs';
import {MsCarousel, MsCarouselModule} from './components/ms-carousel';
import { FamilyMemberEditComponent } from './components/family-member/family-member-edit/family-member-edit.component';
import { FamilyMemberListComponent } from './components/family-member/family-member-list/family-member-list.component';

const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');

export function initializer(login: LoginService) {
  return () => login.init();
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: {direction: hammer.DIRECTION_ALL},
    pan: {direction: hammer.DIRECTION_HORIZONTAL}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    MenuItemComponent,
    HeaderComponent,
    ModalComponent,
    ButtonsBarComponent,
    SecureTemplateComponent,
    SnackBarMessageComponent,
    ConfirmDialogComponent,
    AppProgressBarComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    MakeDepositComponent,
    ConfirmDepositComponent,
    AmountPipe,
    MemberListComponent,
    MemberEditComponent,
    MemberShowComponent,
    ListAbstractComponent,
    HomeComponent,
    OurProjectComponent,
    MembershipComponent,
    RecruitmentComponent,
    MakeDonationComponent,
    JobsComponent,
    ContactUsComponent,
    BlogComponent,
    IsLoginDirective,
    AboutUsComponent,
    FaqComponent,
    FooterBarComponent,
    ProgressBarComponent,
    ConfirmAccountComponent,
    AutocompleteComponent,
    MailConfirmComponent,
    SpinnerOverlayComponent,
    NewsManagementEditorComponent,
    NewsManagementNavigateComponent,
    NewsManagementViewComponent,
    NewsComponent,
    FileManagerComponent,
    FileListComponent,
    DetailsComponent,
    MainComponent,
    FileDownloaderComponent,
    StripePaymentComponent,
    PaypalPaymentComponent,
    OtherPaymentComponent,
    PartnerComponent,
    SingleContactPersonComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FamilyMemberEditComponent,
    FamilyMemberListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatCardModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    MatMomentDateModule,
    FlexLayoutModule,
    AppLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTableModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatToolbarModule,
    MatMenuModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatDividerModule,
    MatRadioModule,
    MatListModule,
    MatSliderModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTabsModule,
    MatDialogModule,
    MatCarouselModule.forRoot(),
    MsCarouselModule,
    StoreModule.forRoot({authentication: authReducer, register: memberShipReducer, rule: userRuleReducer}),
    EffectsModule.forRoot([
      AuthenticationEffect,
      RegisterEffect,
      RuleEffect,
    ]),
    NgxStripeModule.forRoot('pk_live_f3cxctQZwdTntJ5KD2wKs7pa00iYOpmprY'),
    NobleModule.forRoot(nobleConfig),
    BrowserAnimationsModule,

    LayoutModule,
    AppLayoutModule,
  ],
  providers: [
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [LoginService, UserAppService, HttpService]
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorHttpInterceptor, multi: true},
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }

    // provider used to create fake backend
    //   {
    //     // use fake backend in place of Http service for backend-less development
    //     provide: HTTP_INTERCEPTORS,
    //     useClass: FakeBackendInterceptor,
    //     multi: true
    // }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent,
    SecureTemplateComponent,
    SpinnerOverlayComponent,
    AppProgressBarComponent, SnackBarMessageComponent, ConfirmDialogComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller, languageService: LanguageService) {
    router.events.pipe().subscribe(e => {
      viewportScroller.scrollToPosition([0, 0]);
    });

    languageService.init();
  }
}
