import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Persistent } from 'app/model/persistent/persistent';
import { AccessToken } from 'app/model/security/access-token';
import { Country } from '../../model/country/Country';
import { UserAppService } from '../user-service/user-app.service';
import { NotificationService } from '../notification-service/notification.service';
import { DataPage, SearchCriteria } from 'app/model/data-page';
import { GlobalVariables } from '../global-variables';
import { ServiceUtils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class HttpService<T extends Persistent> {
  tokenSubscription: Subscription;
  usertoken: AccessToken;
  public showSpiner: EventEmitter<boolean>;

  countrys: Country[] = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(public userService: UserAppService ,
              public http: HttpClient, public notificationService: NotificationService) {
    this.showSpiner = notificationService.showSpiner;

    this.buildData();
    // this.nkapKeycloakService.tokenSubject.subscribe(token => {
    //   this.usertoken = token;
    // });
  }

  /**
   * Get the user actualy connected
   */
    getUserToken() {
      const access = this.usertoken ? this.usertoken.access_token : null;
      // return userToken || Base64.encode( ":" );
      return access;
  }

  protected getHttpOptions(){
    const userToken: string = this.getUserToken();
    return {
        headers: new HttpHeaders(
          {
              'Content-Type': 'application/json',
              dataType: 'json',
              Authorization: 'Bearer ' + userToken
          })
    };
  }

  public save(entity: T, baseURI: string = '', progressSpinner?: boolean,
              unAuth: boolean = false): Observable<T> {
     const savePath = 'save';
     const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}
     .${savePath.replace(new RegExp('/', 'g'), '.')}`;
     if (progressSpinner === true) { this.showSpiner.emit(true); }
     return this.http.post<T>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${savePath}`,
                               entity, unAuth === true ? undefined : this.getHttpOptions()).pipe(
                                 tap(_ => {if (progressSpinner === true) { this.showSpiner.emit(false); }}),
        catchError( err => {
          if (progressSpinner === true) { this.showSpiner.emit(false); }
          return ServiceUtils.handleError(err);
        })

     );
  }

  // public findById(id: number | string, baseURI: string = '',
  //                  progressSpinner?: boolean, unAuth: boolean = false): Observable<T> {
  //   const findByIdPath: string = 'find-by-id';
  //   const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${findByIdPath.replace(new RegExp('/', 'g'), '.')}`;
  //   const failMessage = `${baseMessage}.fail.error`;
  //   const succedMessage = `${baseMessage}.succed.message`;
  //   if(progressSpinner== true) { this.showSpiner.emit(true);}
  //   return this.http.get<T>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${findByIdPath}/${id}`,
  //   unAuth ? undefined : this.getHttpOptions()).pipe(
  //     tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
  //     catchError( err => {
  //       if (progressSpinner== true) { this.showSpiner.emit(false); }
  //       return ServiceUtils.handleError(err);
  //     })
  //   );
  // }

  public findById(id: number | string, baseURI: string = '',
                  progressSpinner?: boolean, unAuth: boolean = false): Observable<any> {

    console.log(baseURI);
    console.log(id);

    id = +id - 1;
    if (baseURI == 'organisation/country'){
      return new Observable<DataPage<any>>(observer => {
        observer.next(this.countrys[id]);
      });
    }

    return new Observable<DataPage<any>>(observer => {
      observer.next();
    });
  }

  public deleteById(id: number | string, baseURI: string = '',
                    progressSpinner?: boolean, unAuth: boolean = false): Observable<boolean> {
    const deleteByIdPath = 'delete-by-id';
    const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${deleteByIdPath.replace(new RegExp('/', 'g'), '.')}`;
    const failMessage = `${baseMessage}.fail.error`;
    const succedMessage = `${baseMessage}.succed.message`;
	   if (progressSpinner == true) { this.showSpiner.emit(true); }
    return this.http.delete<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${deleteByIdPath}/${id}`,
      unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if (progressSpinner == true) { this.showSpiner.emit(false); }}),
      catchError( err => {
        if (progressSpinner == true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
    );
  }

  public list(baseURI: string = '',
              progressSpinner?: boolean, unAuth: boolean = false): Observable<T[]> {
      const listPath = 'list';
      const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${listPath.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
	     if (progressSpinner == true) { this.showSpiner.emit(true); }
      return this.http.get<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${listPath}`,
      unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if (progressSpinner == true) { this.showSpiner.emit(false); }}),
      catchError( err => {
        if (progressSpinner == true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
    );
  }

  public search(criteria: SearchCriteria | any, baseURI: string = '',
                 progressSpinner?: boolean, unAuth: boolean = false): Observable<DataPage<T>> {
      const searchPath: string = 'search';
      const baseMessage = `${baseURI.replace(new RegExp('/', 'g'), '.')}.${searchPath.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
      if(progressSpinner== true) { this.showSpiner.emit(true);}
      return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/${baseURI}/${searchPath}`,
                               criteria, unAuth === true ? undefined : this.getHttpOptions()).pipe(
	      tap(_ => {if(progressSpinner== true) { this.showSpiner.emit(false);}}),
        catchError( err => {
          if (progressSpinner== true) { this.showSpiner.emit(false); }
          return ServiceUtils.handleError(err);
        })
     );
  }

  public post(data: any, url: string ,
              progressSpinner?: boolean, unAuth: boolean = false): Observable<any> {
      const baseMessage = `${url.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
	     if (progressSpinner == true) { this.showSpiner.emit(true); }
      return this.http.post<any>(`${GlobalVariables.CONTEXT_PATH}/${url}`,
                               data, unAuth === true ? undefined : this.getHttpOptions()).pipe(
	    tap(_ => {if (progressSpinner == true) { this.showSpiner.emit(false); }}),
      catchError( err => {
        if (progressSpinner == true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
     );
  }

  public get(url: string , progressSpinner?: boolean, unAuth: boolean = false): Observable<any> {
      const baseMessage = `${url.replace(new RegExp('/', 'g'), '.')}`;
      const failMessage = `${baseMessage}.fail.error`;
      const succedMessage = `${baseMessage}.succed.message`;
    	 if (progressSpinner == true) { this.showSpiner.emit(true); }
      return this.http.get<any>(`${GlobalVariables.CONTEXT_PATH}/${url}`,
                                unAuth === true ? undefined : this.getHttpOptions()).pipe(
      tap(_ => {if (progressSpinner == true) { this.showSpiner.emit(false); }}),
      catchError( err => {
        if (progressSpinner == true) { this.showSpiner.emit(false); }
        return ServiceUtils.handleError(err);
      })
     );
  }

  public buildData(){
  }

}


