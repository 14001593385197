import { Component, OnInit } from '@angular/core';
import {ConfigService} from '@noble/services/config.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../../../services/http-service/http.service';
import {Member} from '../../../model/organisation/Member';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {GenderType, RelationType} from '../../../model/organisation/Person';
import {FormAction, IFormActionBarButton} from '../../base/buttons-bar/buttons-bar.component';
import {TranslateService} from '@ngx-translate/core';
import {UserAppService} from '../../../services/user-service/user-app.service';
let self;

@Component({
  selector: 'app-family-member-edit',
  templateUrl: './family-member-edit.component.html',
  styleUrls: ['./family-member-edit.component.scss']
})
export class FamilyMemberEditComponent implements OnInit {

  familyMemberForm: FormGroup;
  currentObject: Member;
  public age = 0;

  buttons: IFormActionBarButton[];

  genders: any[] = [
    {value: GenderType[GenderType.FEMALE], text: 'translate.women'},
    {value: GenderType[GenderType.MALE], text: 'translate.boy'},
    {value: GenderType[GenderType.OTHER], text: 'translate.other'}
  ];

  relations: any[] = [
    {value: RelationType[RelationType.SON], text: 'translate.son'},
    {value: RelationType[RelationType.GIRL], text: 'translate.girl'},
    {value: RelationType[RelationType.WIFE], text: 'translate.wife'},
    {value: RelationType[RelationType.HUSBAND], text: 'translate.husband'},
    {value: RelationType[RelationType.DAD], text: 'translate.dad'},
    {value: RelationType[RelationType.MOTHER], text: 'translate.mother'},
    {value: RelationType[RelationType.UNCLE], text: 'translate.uncle'},
    {value: RelationType[RelationType.AUNTY], text: 'translate.aunty'},
    {value: RelationType[RelationType.GRANDFATHER], text: 'translate.grandfather'},
    {value: RelationType[RelationType.GRANDMOTHER], text: 'translate.grandmother'},
    {value: RelationType[RelationType.OTHER], text: 'translate.other'},
  ];

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService<Member>,
    private router: Router,
    private route: ActivatedRoute,
    private msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private userService: UserAppService,
  ) {
    self = this;
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.currentObject = new Member();

    this.buttons = [
      {id: 'save', value: FormAction.CREATE, icon: {type : 'save'},
        text: 'btn.save.label', disabled: false, functionalities: []},
      {id: 'cancel', value: FormAction.CANCEL, icon: {type : 'cancel'},
        text: 'btn.cancel.label', disabled: false, functionalities: []}
    ];
  }

  ngOnInit(): void {
    this.initForm();
    this.familyMemberForm.patchValue(this.currentObject);

    this.familyMemberForm.get('birthdate').valueChanges.subscribe((result) => {
      if (result) {
        this.age = new Date(Date.now()).getFullYear() - new Date(result).getFullYear();
        this.currentObject.birthdate = (new Date(result)).toISOString();
      }
    });

    this.route.paramMap.subscribe(params => {
      const data: {id?: string} = (params as any).params;
      if (data.id != null) {
        this.httpService.findById(data.id, 'organisation/member', true).subscribe( (object) => {
          this.setObjectInView(object);
        }, (errors) => {
          this.msgService.log('member.edit.get.data.error', NotificationType.ERROR);
        });
      }
    });
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id){
      this.save();
    } else if (event && event.id === this.buttons[1].id){
      this.router.navigate(['member/list', {search: true}]);
    }
  }

  getObjectFromView(): Promise<Member>{
    return new Promise((resolve, reject) => {
      let persistent = new Member();
      persistent = Object.assign(this.currentObject, this.familyMemberForm.value);
      persistent.relationType = this.familyMemberForm.get('relationType').value.value;
      persistent.gender = this.familyMemberForm.get('gender').value.value;
      persistent.group = this.userService.getMemberShip();
      persistent.group.id = 101;
      resolve(persistent);
    });
  }

  isValid(){
    let isValid = true;
    if (this.familyMemberForm.invalid === true){
      isValid = false;
    }
    return isValid;
  }

  save() {
    this.getObjectFromView().then((persistence: Member) => {
      if (this.isValid() === true) {
        this.httpService.save(persistence, 'organisation/member', true).subscribe( (result) => {
          this.msgService.log('member.edit.save.succed', NotificationType.SUCCESS);
          this.router.navigate(['member/list', {search: true}]);
        }, (error) => {
          if (typeof error === 'string') {
            this.msgService.log(error, NotificationType.ERROR);
          } else {
            this.msgService.log('member.edit.save.fail', NotificationType.ERROR);
          }
        });
      } else {
        this.msgService.log('member.edit.save.fail', NotificationType.ERROR);
      }
    });
  }

  initForm() {
    this.familyMemberForm = this.fb.group({
      name: [null,  [Validators.required]],
      surname: [null,  [Validators.required]],
      birthdate: [null, [Validators.required]],
      relationType: [null, [Validators.required]],
      gender: [null, [Validators.required]],
    });
  }

  setObjectInView(object: Member) {
    if (object) {
      const relationType: {text: string, value: string} = this.relations.find((e: {text: string, value: string}) => {
        return e.value === object.relationType;
      });

      const gender: {text: string, value: string} = this.genders.find((e: {text: string, value: string}) => {
        return e.value === object.gender;
      });
      this.currentObject = object;
      this.currentObject.relationType = relationType;
      this.currentObject.gender = gender;
      this.familyMemberForm.patchValue(this.currentObject);
    }
  }

  display(val: {value: string, text: string}) {
    let response = '';
    if (val) {
      response = self.translateService.instant(val.text);
    }
    return response;
  }

}
