import {Component, OnInit, OnDestroy, ViewChild, Input} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {Subscription, Subject, Observable} from 'rxjs';
import { ButtonsBarComponent, IFormActionBarButton, FormAction } from '../../base/buttons-bar/buttons-bar.component';
import { Router } from '@angular/router';
import {ConfigService} from '@noble/services/config.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../store/login/login.state';
import {User} from '../../../model/security/user';
import {State} from '../../../store/login/reducers/authentication.reducer';
import {RegisterService} from '../../../services/register/register.service';
import {StripePaymentComponent} from '../stripe-payment/stripe-payment.component';

@Component({
  selector: 'app-make-deposit',
  templateUrl: './make-deposit.component.html',
  styleUrls: ['./make-deposit.component.scss']
})
export class MakeDepositComponent implements OnInit, OnDestroy {

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  user = new User();
  userAge = 0;

  amount: number;
  depositAmountControl: FormControl;
  depositAmountSubscription: Subscription;
  depositAmountSubject: Subject<number> = new Subject<number>();

  // @ViewChild('btnBar')
  btnBarCmp: ButtonsBarComponent;
  public buttons: IFormActionBarButton[];

  @ViewChild('stripePaymentComponent') stripePaymentComponent: StripePaymentComponent;

  functions: {icon: string, value: string, color: string}[] = [];

  isHandset$: Observable<boolean>;

  favoriteOption: {text: string, value: string, description: string};
  options: {text: string, value: number, description: string}[] = [
    {
      text: 'deposit.option.subscription.text.short',
      value: 76,
      description: 'deposit.option.subscription.text'
    },
    {
      text: 'deposit.option.unique.inscription.text.short',
      value: 50,
      description: 'deposit.option.unique.inscription.text'
    }
  ];

  constructor(
    protected router: Router,
    private _configService: ConfigService,
    private _registerService: RegisterService,
    private store: Store<LoginState>,
    private breakpointObserver: BreakpointObserver) {

    this.authenticationState$ = this.store.select(selectAuthenticationState);

    this.initControl();

    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.buttons = [
      {id: 'save', value: FormAction.UPDATE, icon: {type: 'save', color: '#ff9933'},
        text: 'btn.confirm.label', disabled: true, functionalities: []}
    ];

    this.functions = [
      {
        icon: 'done',
        value: 'Register New member',
        color: 'primary'
      },
      {
        icon: 'done',
        value: 'Finish Dificulty',
        color: 'primary'
      },
      {
        icon: 'done',
        value: 'Part of association',
        color: 'warn'
      },
      {
        icon: 'done',
        value: 'Assistance',
        color: 'primary'
      },
    ];
  }

  ngOnInit(): void {

    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.user = state.user.user;

        if (!this.user.administrator) {
          this._registerService.findMemberShipByLogin(this.user.login).then(member => {
            this.userAge = new Date(Date.now()).getFullYear() - new Date(member.birthdate).getFullYear();
            this.setAmountByAge();
          });
        }
      }
    });

    this.depositAmountControl.valueChanges.subscribe((val: string) => {
      if (this.depositAmountControl.valid) {
        this.buttons[0].disabled = false;
      } else {
        this.buttons[0].disabled = true;
      }
    });

    this.depositAmountSubscription = this.depositAmountSubject.subscribe(amount => {

    });

    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(
        map(result => !result.matches),
        shareReplay()
      );
  }

  ngOnDestroy() {
    this.depositAmountSubscription.unsubscribe();
    this.authenticationSubscription.unsubscribe();
  }

  emitAmount() {
    this.depositAmountSubject.next(this.amount);
  }

  initControl() {
    this.depositAmountControl = new FormControl('', [Validators.required, Validators.pattern('[1-9][0-9]*')]);
  }

  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ) {
      this.router.navigate(['/deposit/confirm', {amount: this.amount}]);
    }
  }

  setAmountByAge() {
    if (this.userAge <= 59) {
      this.options = this.options.filter((e: {text: string, value: number, description: string}) => {
        if (e.text === 'deposit.option.subscription.text.short') {
          e.value = 76;
        } else if (e.text === 'deposit.option.unique.inscription.text.short') {
          e.value = 50;
        }
        return e;
      });
    } else if (this.userAge > 59) {
      this.options = this.options.filter((e: {text: string, value: number, description: string}) => {
        if (e.text === 'deposit.option.subscription.text.short') {
          e.value = 226;
        } else if (e.text === 'deposit.option.unique.inscription.text.short') {
          e.value = 200;
        }
        return e;
      });
    }
  }

  changeTap(event) {
    console.log(event);
  }

  changeOption(event){
    if (event) {
      this.stripePaymentComponent.depositAmount = event.value.value;
    }
  }
}
