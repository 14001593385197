import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  templateUrl: 'navbar-menu.component.html',
  selector: 'app-navbar-menu',
  styleUrls: [ 'navbar-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'navbar-menu'
  }
})
export class NavbarMenuComponent {
  menuLinks: { routerLink: string, label: string }[] = [
    {routerLink: 'home', label: 'app.home.label'},
    {routerLink: 'home/about-us', label: 'app.about-us.label'},
    {routerLink: 'home/our-project', label: 'app.our-project.label'},
    {routerLink: 'home/membership', label: 'app.membership.label'},
    {routerLink: 'home/contact-us', label: 'app.contact-us.label'},
    // {routerLink: 'home/make-donation', label: 'app.make-donation.label'}
  ];
}
