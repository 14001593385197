import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '@noble/services/config.service';
import {ListAbstractComponent} from '../../base/abstract/list-abstract-component';
import {Member} from '../../../model/organisation/Member';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '../../../services/http-service/http.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import {LoginState, selectAuthenticationState} from '../../../store/login/login.state';
import {State} from '../../../store/login/reducers/authentication.reducer';
import {MemberShip} from '../../../model/register/MemberShip';
import {User} from '../../../model/security/user';
import {RegisterService} from '../../../services/register/register.service';
import {FormAction} from '../../base/buttons-bar/buttons-bar.component';

@Component({
  selector: 'app-family-member-list',
  templateUrl: './family-member-list.component.html',
  styleUrls: ['./family-member-list.component.scss']
})
export class FamilyMemberListComponent extends ListAbstractComponent<Member> implements OnInit, OnDestroy{

  authenticationState$: Observable<any>;
  authenticationSubscription: Subscription;

  currentUser: User;
  currentMemberShip: MemberShip;

  constructor(
    protected ref: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected httpService: HttpService<Member>,
    protected userService: UserAppService,
    protected msgService: NotificationService,
    protected translateService: TranslateService,
    private _configService: ConfigService,
    private store: Store<LoginState>,
    private registerService: RegisterService,
  ) {
    super(ref, httpService, router, route, userService, msgService, translateService);

    this.authenticationState$ = this.store.select(selectAuthenticationState);
    this.searchBaseUrl = 'organisation/member';
    this._configService.config = {
      layout: {
        style   : 'app-navbar',
      }
    };

    this.buttons = [
      {id: 'edit', value: FormAction.UPDATE, text: 'btn.update.label', cssClass: 'btn-update' , disabled: false,
        icon: {type : 'edit', color: '#ff9933'}},
      {id: 'delete', value: FormAction.DELETE, text: 'btn.delete.label', cssClass: 'btn-delete' , disabled: false,
        icon: {type : 'delete_forever', color: '#ff9933'}},
    ];
  }

  ngOnInit() {
    super.ngOnInit();
    this.authenticationSubscription = this.authenticationState$.subscribe((state: State) => {
      if (state && state.errorLogin === null) {
        this.currentUser = state.user.user;
        this.registerService.findMemberShipByLogin(this.currentUser.login).then(memberShip => {
          this.currentMemberShip = memberShip;
          this.criteria = {
            ...this.criteria,
            memberShipId: this.currentMemberShip.id
          };
        });
      }
    });
  }

  public viewElement(element) {
    this.router.navigate(['member/show', {id: element.id}]);
  }

  getYear(element: Member) {
    if (element === undefined || element === null || element.birthdate === undefined || element.birthdate === null) {
      return 0;
    }
    return  new Date(Date.now()).getFullYear() - new Date(element.birthdate).getFullYear();
  }
  btnActionClick(event) {
    if (event && event.id === this.buttons[0].id ){
      this.router.navigate(['family/show']);
    } else if (event && event.id === this.buttons[1].id){
      if ( !this.selection.selected || this.selection.selected.length !== 1) {
        this.msgService.log('member.list.select.to.modify.error', NotificationType.ERROR);
      }else{
        this.router.navigate(['family/show', {number: this.selection.selected[0].id}]);
      }
    }
    else if (event && event.id === this.buttons[2].id){
      if ( !this.selection.selected || this.selection.selected.length < 1){
        this.msgService.log('member.list.select.to.delete.error', NotificationType.ERROR);
      }else{
        this.deleteSelected();
      }
    }
  }

  deleteSelected(){
    this.msgService.confirm(this.translate.transform('member.select.to.delete.message')).subscribe( (result) => {
      if (result === true){
        const memberSelected = this.selection.selected[0];
        this.httpService.deleteById(memberSelected.id, 'organisation/member').subscribe( (val) => {
          this.msgService.log('member.delete.succeed', NotificationType.SUCCESS);

          this.searchClicked({});
        }, (error) => {
          this.msgService.log('member.delete.fail', NotificationType.ERROR);
        });
      }
    });
  }

  ngOnDestroy()  {
    this.authenticationSubscription.unsubscribe();
  }
}
