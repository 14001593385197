import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificationService, NotificationType} from '../../../services/notification-service/notification.service';
import {ConfigService} from '@noble/services/config.service';
import {RegisterService} from '../../../services/register/register.service';
import {UserAppService} from '../../../services/user-service/user-app.service';
import {Router} from '@angular/router';
import {ConfirmUser} from "../../../model/security/confirm-user";
import {EmailConfirm} from "../../../store/login/actions/authentication.action";
import {GlobalVariables} from "../../../services/global-variables";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  loading = false;
  submitted = false;
  email: string;

  constructor(
    private formBuilder: FormBuilder,
    private msgService: NotificationService,
    private _configService: ConfigService,
    private userService: UserAppService,
    private router: Router
  ) {
    this._configService.config = {
      layout: {
        style   : 'app-navbar-out',
        appNavbarOut: {
          showLoginButton: true,
          showRegisterButton: true,
        },
      }
    };
  }

  ngOnInit(): void {
    this.msgService.log('forgot.password.confirm.info', NotificationType.INFO);
    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(/[+a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/)]]
    });
  }
  public isValid() {
    this.getObjectFromView();
    return this.forgotPassForm.valid;
  }

  getObjectFromView(){
    this.email = this.forgotPassForm.get('email').value;
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgotPassForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.isValid()) {
      this.msgService.showSpiner.emit(true);
      this.userService.sendCodeForReset(this.forgotPassForm.get('email').value).subscribe(res => {
        this.loading = false;
        this.goToLogin();
        this.msgService.log('forgot.password.confirm.success', NotificationType.SUCCESS);
      }, error => {
        this.goToLogin();
        this.msgService.log('forgot.password.confirm.success', NotificationType.SUCCESS);
      });
    }
  }
}
